import React, { useState } from 'react'
import classNames from 'classnames'

import Type from '@/component/Primitive/Type'
import SmartLink from '@/component/Primitive/SmartLink'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Container from '@/component/Primitive/Container'
import ButtonBase from '@/component/Primitive/ButtonBase'

import styles from './CookieBanner.module.scss'
import { useTagContext } from '@/component/Context/TagContext'

const CookieBanner = () => {
  const { enableAnalytics } = useTagContext(false)
  const [cookieAccepted, setCookieState] = useState(false)

  const cookieBannerDismissed =
    typeof window !== 'undefined'
      ? window.localStorage.getItem('cookieBannerDismissed')
      : 'false'

  const [isCookieVisible, setCookieVisible] = useState(
    !cookieBannerDismissed || cookieBannerDismissed === 'false'
  )

  const storeDismissal = () => {
    window.localStorage.setItem('cookieBannerDismissed', true)
  }

  const handleCookieAccept = () => {
    setCookieState(true)
    enableAnalytics(true)
    storeDismissal()
  }

  const handleCookieDismiss = () => {
    setCookieVisible(false)
    storeDismissal()
  }

  if (!isCookieVisible) return null
  if (cookieAccepted) {
    return (
      <Container gutter className={styles.CookieBanner}>
        <div className={styles.TextWrapper}>
          <Type size="display0" bold className={styles.Title}>
            Thank you for accepting cookies
          </Type>
          <p className={styles.Description}>
            You can now hide this message or find out more about cookies
          </p>
        </div>
        <div className={styles.ButtonWrapper}>
          <ButtonStandard
            onClick={handleCookieDismiss}
            className={styles.Button}
          >
            <Type size="base" bold>
              Hide
            </Type>
          </ButtonStandard>

          <SmartLink
            className={styles.Link}
            to="/section"
            as="/privacy-statement"
          >
            <ButtonStandard className={styles.Button}>
              <Type size="base" bold>
                More info
              </Type>
            </ButtonStandard>
          </SmartLink>
        </div>
      </Container>
    )
  }

  return (
    <Container gutter className={styles.CookieBanner}>
      <div className={styles.TextWrapper}>
        <Type size="display0" bold className={styles.Title}>
          We use cookies on this site to enhance your user experience
        </Type>
        <p className={styles.Description}>
          By clicking the I agree button, you agree to us doing so.{' '}
          <SmartLink
            className={styles.MoreInfo}
            to="/section"
            as="/privacy-statement"
          >
            No, give me more info{' '}
          </SmartLink>
        </p>
      </div>
      <div className={styles.ButtonWrapper}>
        <ButtonStandard
          onClick={handleCookieAccept}
          className={classNames(styles.Button, styles.Button)}
        >
          <Type size="base" bold>
            OK, I agree
          </Type>
        </ButtonStandard>
        <ButtonBase
          onClick={handleCookieDismiss}
          className={styles.AcceptButton}
        >
          <Type size="base" bold>
            No, thanks
          </Type>
        </ButtonBase>
      </div>
    </Container>
  )
}

export default CookieBanner
